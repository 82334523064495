.twittertimeline {
	box-sizing:border-box;
	position:relative;
	overflow:hidden;
	min-height:100px;
	max-height:650px;
	max-width:520px;
	margin-right:auto !important;
	margin-left:auto !important;
}

.twittertimeline iframe {
	box-shadow: none !important;
	box-sizing:border-box !important;
	margin: 0 !important;
	max-height: inherit;
}

.fluid-align > .s6 > .widget.twittertimeline {
	overflow: hidden !important;
}

@media print {
	.twittertimeline {
		display: none;
	}
}
