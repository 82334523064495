.vimeo {
	position: relative;
	overflow: hidden;
	max-width: 100%;
	min-height:auto;
	height: auto;
}

.vimeo > .vimeoWrapper {
	position: relative;
	overflow: hidden;
	max-width: 100%;
	min-height:auto;
	height: auto;
	padding-bottom: 56.25%;
}

.vimeo > .vimeoWrapper > iframe {
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	z-index: 1;
	border: 0;
	overflow: hidden;
}