/* plugin styles */
.js .plugin{
	min-height: auto;
}

.plugin {
	position: relative;

	&:after{
		content: '';
		display: block;
		clear: both;
	}
}

.plugin > img {
	width: 100%;
}

.plugin > div {
	box-sizing: border-box;
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	z-index: 90;
}

.no-js {
	.plugin .alert {
		margin: 0;
	}
}
