@require '../../common/css/mixins';

// --- DARKMODE ---
html.darkmode {
  .tipform {
    color: $darkmode-text;
    .contact-information {
      color: $darkmode-text;
    }
    a[href] {
      color: $darkmode-link;
    } 
    dd {
      color: $darkmode-link;
    }
  }

  #tips-form {
    @media screen and (max-width: 720px) {
      padding: 20px;
      fieldset > div {
        padding-right: 0px;
      }
    }
    padding: 0;  
    padding-top: 20px;
    border: none;
    border-top: 1px solid $darkmode-border;
    background-color: transparent;
    color: $darkmode-text;

    textarea,
    input {
      border-color: transparent;
      background-color: $nrk-color-core-blue-800;
      color: $darkmode-text; 
      &:focus-visible {
        outline: 2px solid $darkmode-link;  
      }
      &::placeholder {
        color: $darkmode-text-byline;  
      }
      &.invalid:not(:focus-visible) {
        border-color: $nrk-color-red-600;
      }
    } 
    fieldset.footer {
      button {
        background-color: $darkmode-background;
        color: $darkmode-text;
      }
    }
    .remove-file-input {
      color: $nrk-color-red-600;
    }
  }

  .whistleblower-info {
    padding: 0;
    background-color: transparent;
    border: none;
    color: $darkmode-text;
  }
}
// --- DARKMODE END


.tipform {
    margin: 0 !important;
    #tips-form {
        min-height: 650px;
    }
    .title {
        margin: 0 0 10px 20px;
        color: #00A0CD;
    }
    form,
    .whistleblower-info {
        background-color: #f1f1f1;
        border: 1px solid #c8c8c8;
        position: relative;
        h2 {
            font-size: 23px;
        }
    }
    form {
        color: #26292a;
        margin-bottom: 20px;
        padding: 20px 0 8px 20px;
        .pre-submit {
            margin-top: 0;
            margin-bottom: 0;
        }
    }
    .whistleblower-info {
        color: #26292a;
        margin-bottom: 60px;
        padding: 0 0 4px 20px;
    }
    .contact-information,
    p {
        font-size: 15px;
        line-height: 1.57em;

        a {
            color: #0059b3;
            border-bottom: 1px solid #c8c8c8;
            &:hover {
                border-color: #0059b3;
            }
        }
        &p {
            margin: 0 0 10px;
        }
    }
    .contact-information {
        font-style: normal;
        overflow: auto;
        position: relative;
        color: #26292a;

        dl {
            float: left;
            &:first-child {
                min-width: 250px;
            }

            dt,
            dd {
                float: left;
            }

            dt {
                clear: left;
                &::after {
                    content: ":";
                }
            }

            dd {
                font-weight: 600;
                margin-left: 4px;
                .rate {
                    font-weight: normal;
                }
            }
        }
    }
    .overlay {
        background-color: #f1f1f1;
        color: #404041;
        position: absolute;
        z-index: 300;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        display: none;
        .spinner {
            display: inline-block;
            -webkit-animation-name: rotate;
            -webkit-animation-duration: 1.3s;
            -webkit-animation-iteration-count: infinite;
            -webkit-animation-timing-function: linear;
            -webkit-transform-origin: center;
            -moz-animation-name: rotate;
            -moz-animation-duration: 1s;
            -moz-animation-iteration-count: infinite;
            -moz-animation-timing-function: linear;
            -ms-animation-name: rotate;
            -ms-animation-duration: 1s;
            -ms-animation-iteration-count: infinite;
            -ms-animation-timing-function: linear;
        }
        p {
            font-size: 15px;
        }
        p strong {
            font-weight: 600;
            font-size: 18px;
        }
        dt {
            font-weight: bold;
        }
        dd {
            margin: 0
            padding-bottom: 10px;
        }
        h1,
        span {
            display: block;
            font-size: 26px;
            font-weight: normal;
            text-align: center;
            margin-bottom: 20px;
        }
    }
    .tips-form__contact-info {
        justify-content: center;
        padding: 20px 0;
    }
    .overlay > div {
        width: 100%;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        padding: 20px;
        margin-top: 20px;
        display: none;
        position: absolute;
        z-index: 200;
    }
    .display-message .overlay {
        display: block;
    }
    .success-message {
        background: transparent url(img/success.png) no-repeat top center;
        position: relative;
        z-index: 100;
    }
    .success-message,
    .failure-message {
        padding-top: 100px !important;
    }
    .failure-message {
        text-align: center;
        background: transparent url(img/failure.png) no-repeat top center;
    }
    .progress-message {
        text-align: center;
    }
    .success .success-message,
    .failure .failure-message,
    .progress .progress-message {
        z-index: 400;
        display: block;
    }
    /* dummy */
    fieldset {
        width: 100%;
        float: left;
        margin: 0 0 20px 0;
        padding: 0;
        border: 0;
    }
    fieldset > div {
        padding-right: 20px;
    }
    label {
        display: block;
        margin-bottom: 7px;
        font-size: 16px;
        font-weight: 600;

        label.error {
            color: #ff0000;
        }
    }
    .text.invalid {
        border-color: #ff0101;
    }
    .text.valid {
        background-image: url(img/valid.png);
        background-repeat: no-repeat;
        background-position: right center;
    }
    form.disabled .text {
        background-color: #e7e7e7;
    }
    form.disabled #map,
    form.disabled .footer,
    form.disabled label {
        opacity: 0.4;
    }
    .form-message {
        display: none;
        font-size: 13px;
        background-color: #fefff1;
        position: relative;
        top: -10px;
        line-height: 20px;
        padding-left: 10px;
        p {
            margin-top: -10px;
            padding-bottom: 5px;
            padding-top: 5px;
        }
    }
    small {
        font-size: 14px;
        font-weight: normal;
        font-style: italic;
    }
    .text {
        width: 100%;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        border: none;
        margin-bottom: 20px;
        font-size: 15px;
        padding: 7px;
        border: 1px solid #cccccc;
    }
    textarea.text {
        padding-top: 5px;
        line-height: 1.4em;
    }
    .text:focus {
        background-color: #fefff1;
        border-color: #156d01;
    }
    .attachment-field {
        margin-bottom: 20px;
    }
    .file {
        font-size: 13px;
        margin-bottom: 5px;
        outline: none;
        width: 70%;
    }
    .remove-file-input {
        border: medium none;
        color: #FF0101;
        background: transparent;
        clear: right;
        font-size: 13px;
        float: right;
        width: 30%;
    }
    fieldset.footer {
        clear: both;
    }
    #map {
        height: 401px;
        margin-bottom: 20px;
        border: 1px solid #cccccc;
    }
    footer {
        margin: 0 10px 0 10px;
        .process {
            text-align: center;
            img {
                max-width: 100%;
                height: auto;
            }
        }
        h2 {
            font-size: 19px;
            margin-bottom: 10px;
        }
        p {
            font-size: 15px;
            line-height: 1.4em;
        }
    }
}
.tips-form--hidden {
    display: none;
}
@media screen and (min-width:720px) {
    .tipform footer {
        margin: 0 0 0 0;
    }
    .tipform {
        margin: 0 10px 20px 10px !important;
    }
    .tipform .title {
        font-size: 38px;
        margin-left: 0;
        margin-bottom: 30px;
    }
    .tipform fieldset {
        width: 50%;
    }
    .tipform textarea.text {
        min-height: 170px;
    }
    .tipform .overlay > div {
        position: absolute;
        width: 480px;
        left: 50%;
        margin-left: -260px;
        margin-top: 70px;
    }
}

@media screen and (max-width:719px) {
    .contact-information {
        padding-left: 20px;
    }
}
