@require '../../common/css/mixins';

// --- DARKMODE ---
html.darkmode {
  .tipbox {
    background: $darkmode-background;
    border: none;
    .tipbox-header, .tipbox-content, .tipbox-pitch, .plug-svg-arrow  {
      color: $darkmode-text;
    } 
    a {
      background-color: $darkmode-background;
      border-bottom: none;
      transition: background-color 0.2s;
      &:hover {
        background-color: $nrk-color-core-blue-900;
        border-bottom: none;
        .plug-svg-arrow {
          color: $darkmode-text;
        }
      }
    }
  }
}
// --- DARKMODE END ---
 
.tipbox {
	margin: 12px 0 0 0;
	border: 1px solid $nrk-color-gray-200;
	font-size: 14px;
	line-height: 1.4em;
	background: $color-white;
}

.tipbox a,
.tipbox a:link {
	color:$nrk-color-gray-800 !important;
	display:block;
	padding: 10px 26px 10px 14px;
	position:relative;
}

.tipbox a:hover {
	background-color:$nrk-color-core-blue-50;
	border-bottom-color: #c8c8c8 !important;
}

.tipbox-header {
	font-nrk-sans(20px, 25px);
	font-weight: 600;
	margin:0 0 6px 0;
	color:$nrk-color-core-blue-600;

}

.tipbox .plug-svg-arrow {
	display:block;
	position:absolute;
	right:15px;
	top:50%;
	margin-top:-11px;
	width:9px;
	height:23px;
	color: $nrk-color-core-blue-600;;
}

.tipbox a:hover .plug-svg-arrow {
	color: $nrk-color-core-blue-600;;
}

.tipbox-content {
	margin:0 0 2px 0;
}

.tipbox-pitch {
	font-weight: 600;
}
