@require '../../common/css/mixins';

.tipbanner {
	padding-top: 15px;
	font-size: 14px;
	margin-bottom:50px !important;
}

.tipbanner h4 {
	font-nrk-sans(25px, 32px);
	margin: 0;
	padding: 0;
}

.tipbanner h4 a {
	border-bottom:0;
}

.tipbanner p {
	line-height: 1.3em;
}

.negative .tipbanner p {
	color:#fff;
}

.negative .tipbanner .text-body a:link,
.negative .tipbanner .text-body a:visited {
	color:$nrk-color-core-blue-400;
	border-bottom-color: $nrk-color-core-blue-400;
}

.negative .tipbanner .text-body a:hover {
	border-bottom-color: #00b8f1;
}

@media screen and (min-width: 720px) {
	.tipbanner h4 {
		fontSize(35px, 44px);
	}
}
