.spotify {
	position: relative;
	height:80px;
}

.spotify > iframe {
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	z-index: 1;
	overflow: hidden;
	border: 0;
	background-color: transparent;
}

.spotify noscript {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 2;
}
