.dummy {
	background: #e7e7e7 url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAAECAYAAACp8Z5+AAAAJklEQVQImVXHoQEAMAgDsO7/Q8BzYKYQxOUhq7snCFJVg5wgJ8gH2Owoh/DInokAAAAASUVORK5CYII=') repeat;
	padding-bottom: 50px;
	border: 1px solid #c6cdcd;
}

.dummy h2 {
	margin: 0;
	font-size: 25px;
	text-align: center;
	color: white;
	margin-top: 50px;
	text-shadow: 1px 1px 4px #afb5b5;
	word-wrap: break-word;
	padding: 0 10px 50px 10px;
}

@media screen and (min-width: 480px) {
	.dummy h2 {
		font-size: 40px;
	}

}

