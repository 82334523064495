.tweet {
	box-sizing:border-box;
	position:relative;
	overflow:hidden;
	min-height:100px;
	max-width:520px;
	margin-right:auto !important;
	margin-left:auto !important;
}

.tweet.bulletin-attachment {
	padding-bottom: 1px;
}

.tweet iframe {
	box-shadow: none !important;
	box-sizing:border-box !important;
	margin: 0 !important;
}

@media print {
	.tweet {
		display: none;
	}
}