.giphy iframe {
  position: absolute;
  width: 100%;
  height: 100%;
}

.giphy figure.image a {
  color: #0059b3;
  border-bottom: 1px solid #c8c8c8;
}
